@use "../setting/app" as *;

.c-sidebar{
  width: 23%;
  &__item{
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
    line-height: 1.16;
    margin-bottom: 20%;
    letter-spacing: 0.05vw;
    position: relative;
    overflow: hidden;
    color: transparent;
    @include mq(sp){
      font-size: 1.4rem;
      line-height: 1.5;
      left: 10%;
    }
    &:hover{
      & a::before {
        opacity: 1;
        transform: translateY(-50%);
      }
      & a::after {
        opacity: 0;
        transform: translateY(-50%);
      }
    }
    & a::before{
      top: 50%;
      opacity: 0;
    }
    & a::after{
      top: 0;
    }
  }
  &__list{
    font-family: $poppins;
    font-weight: lighter;
    margin: 65% 0 0 30%;
    @include mq(sp){
      margin: 0 0 0 30%;
    }
  }
}

// before,after共通項
.c-sidebar__item a::before, .c-sidebar__item a::after{
  content: attr(data-text);
  display: block;
  position: absolute;
  left: 9.2%;
  color: #222;
  -webkit-transition: opacity 400ms cubic-bezier(0.87, 0, 0.13, 1),-webkit-transform 400ms cubic-bezier(0.87, 0, 0.13, 1);
  transition: opacity 400ms cubic-bezier(0.87, 0, 0.13, 1),-webkit-transform 400ms cubic-bezier(0.87, 0, 0.13, 1);
  transition: transform 400ms cubic-bezier(0.87, 0, 0.13, 1),opacity 400ms cubic-bezier(0.87, 0, 0.13, 1);
  transition: transform 400ms cubic-bezier(0.87, 0, 0.13, 1),opacity 400ms cubic-bezier(0.87, 0, 0.13, 1),-webkit-transform 400ms cubic-bezier(0.87, 0, 0.13, 1);
}
