// margin
$center: 0 auto;

// width
$pc1: 70%;
$pc2: 80%;

//border
$solid: solid 1px #000;
$solid-half: solid .5px #000;
$check: solid 3px #000;

// transition
$animation: all .5s;

// translate
// 左右
$translate-lr-left: translate(100%, 32%);
$translate-lr-right: translate(210%, 61%);
$translate-lr-bottom: translate(157%, 100%);
$translate-lr-top: translate(151%, -7%);

// 上下
$translate-tb-top: translate(165%, 0);
$translate-tb-bottom: translate(143%, 107%);
$translate-tb-left: translate(100%, 52%);
$translate-tb-right: translate(210%, 54%);
