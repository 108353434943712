@use "../setting/app" as *;

.c-text{
  &__wrap{
    font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
    margin: 0 0 8% 0;
    & div{
      margin: 0 0 3% 0;
      line-height: 1.7;
      & h4{
        font-weight: bold;
        font-size: 2.4rem;
        margin: 0;
        @include mq(sp){
          font-size: 1.6rem;
        }
      }
      &:first-child{
        line-height: 2;
        margin: 0 0 6% 0;
        & h3{
          display: block;
          font-size: 3.4rem;
          font-weight: bold;
          line-height: 2;
          letter-spacing: 0.1vw;
          @include mq(sp){
            font-size: 2rem;
          }
        }
      }
    }
    & p{
      // font-size: 1.4rem;
    }
  }
}