@use "../setting/app" as *;

.p-circle{
  width: 40%;
  margin: 2% auto;
  position: relative;
  @include mq(sp){
    width: 100%;
    margin: 2% auto 17% auto;
  }
  &__line{
    fill: transparent;
    stroke-width: 20%;
    width: 40vw;
    height: 40vw;
    transform: rotate(-90deg);
    @include mq(sp){
      width: 43vw;
      height: 43vw;
    }
    & circle{
      background-color: unset;
      fill: transparent;
      stroke: rgb(255, 255, 255);
      stroke-width: 0.5px;
    }
  }
  &__text{
    display: inline-block;
    color: #fff;
    position: absolute;
    top: 10%;
    left: 10%;
    text-align: center;
    font-size: 2rem;
    padding: 21.7rem 7.7rem;
    border-radius: 50%;
    z-index: 4;
    @include mq(sp){
      width: 100%;
      padding: 0;
      top: 45%;
      left: 0%;
      & p{
        font-size: 1.4rem;
        width: 100%;
        position: absolute;
        top: 12rem;
      }
    }
    &:hover{
      animation: contactText 0s ease 0.6s 1 forwards;
      & ~ .p-circle__line > .line2{
        stroke-width: 1%;
        animation: contactLine 1s ease;
      }
    }
    & h2{
      font-size: 8rem;
      font-family: $ITCAvantGardeStd;
      margin: 0 0 4%;
      @include mq(sp){
        font-size: 2.6rem;
      }
    }

  }
  &__wrap{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-around;
  }
}

#entry{
  position: relative;
  & .p-circle{
    margin: 0 auto;
  }
  & .p-circle__text{
    top: 12%;
    left: 17%;
    padding: 12rem 7.5rem;
    & h2{
      margin: 0 0 12%;
      @include mq(sp){
        margin: 3% 0;
      }
    }
    @include mq(sp){
      padding: 0;
      top: 39%;
      left: 0;
    }
    & p{
      font-size: 4.4rem;
      font-family: $Noto;
      @include mq(sp){
        font-size: 1.8rem;
      }
    }
    & span{
      display: block;
      @include mq(sp){
        display: unset;
      }
    }
  }
  & .p-circle__line{
    height: 35vw;
    @include mq(sp){
      width: 50vw;
      height: 45vw;
    }
  }
}

@keyframes contactText{
  to{ color: #000;
      background-color: #fff;}
}

@keyframes contactLine {
  0%{stroke-dasharray: 0, 330%;}
  to{stroke-dasharray: 330%, 330%;}
}