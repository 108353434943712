@use "../setting/app" as *;

.c-icon{
  @include icon;
  &__arrow{
    position: absolute;
    width: 9%;
    top: 80%;
    left: 50%;
    animation: scroll-arrow 1s ease-in-out infinite;
    &--right{
      position: absolute;
      width: 60%;
      top: 42%;
      animation: more-arrow 1s ease-in-out infinite;
    }
  }
  &__more{
    position: absolute;
    &:hover{
      @include colorBtn;
    }
    &--arrow{
      position: relative;
      height: 1px;
      animation: more-arrow 1s ease-in-out infinite;
      &::before{
        top: .9rem;
        left: 195%;
        width: 30%;
        transform: rotate(30deg);
        @include mq(sp){
          top: 1.9vw;
          left: 12vw;
        }
      }
    
      &::after{
        width: 100%;
        top: 1.2rem;
        left: 120%;
        @include mq(sp){
          top: 2.3vw;
          left: 7vw;
        }
      }
    }
    &--service{
      color: #fff;
      position: absolute;
      bottom: 10%;
      left: 12%;
      border: solid 0.5px #fff;
      border-radius: 65%;
      z-index: 4;
      @include mq(sp){
        left: 70%;
      }
      &:hover{
        background-color: #fff;
        color: #000;
      }
    }
  }
  &__scroll{
    position: absolute;
    bottom: 4%;
    right: 4%;
    background-color: #fff;
    @include mq(sp){
      bottom: -5%;
      border: none;
      z-index: 2;
    }
    &--arrow{
      position: relative;
      animation: scroll-arrow 1s ease-in-out infinite;
      transform: rotate(-90deg);
      height: 1px;
    
      &::before{
        top: 0.5rem;
        /*rotateしているのでleftが縦軸になる*/
        transform: rotate(30deg);
        width: 40%; /*widthが線の長さになる*/
        @include mq(sp){
          top: 200%;
          width: 30%;
        }
      }
      &::after{
        width: 150%;
        @include mq(sp){
          width: 100%;
        }
      }
    }
  }
}

.c-icon__more--arrow:before,.c-icon__more--arrow:after{
  content: "";
  display: block;
  position: absolute;
  border: $solid-half;
  z-index: 2;
}

@keyframes more-arrow{
  0%{transform: translate(20%);}
  50%{transform: translate(40%);}
  to{transform: translate(20%);}
}

.c-icon__scroll--arrow::before, .c-icon__scroll--arrow::after{
  content: "";
  display: block;
  border: $solid-half;
  position: absolute;
  left: -7rem;
  z-index: 2;
  @include mq(sp){
    left: -100%;
  }
}

@keyframes scroll-arrow{
  /* rotateも同時指定しないとanimation時に外れる */
  0%{transform: translateY(0);}
  50%{transform: translateY(20%);}
  to{transform: translateY(0);}
}

#service .c-icon__more--arrow::before, #service .c-icon__more--arrow::after{
  border: solid 0.5px #fff;
}
