@use "../setting/app" as *;

@include mq(sp){
  header{
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 97;
    & .c-logo{
      & img{
        width: 100%;
      }
    }
  }
}
