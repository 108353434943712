@use "../setting/app" as *;

.c-scroll{
  &__img{
    display: flex;
    overflow: hidden;
    background-color: #000;
    & > div{
      display: flex;
      opacity: 0.4;
      background-color: #fff;
      animation: loop-right 20s infinite linear both;
    }
    &--recruit{
      margin: 0 0 19rem 0;
      @include mq(sp){
        margin: 0 0 5rem 0;
      }
      & img{
        width: calc(100vw / 5);
        @include mq(sp){
          width: calc(100vw / 3);
        }
      }
    }

  }
  &__logo{
    overflow: hidden;
    display: flex;
    & > div{
      display: flex;
      animation: loop-left 20s infinite linear both;
      & > img{
        margin-right: 100px;
      }
    }
  }
  &--recruit{
    position: relative;
    @include mq(sp){
      margin: 50rem 0 0 0;
    }
    & p{
      font-size: 2.5rem;
      font-family: $Noto;
      display: block;
      // width: 90%;
      color: #fff;
      line-height: 2;
      // line-height: 3;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      @include mq(sp){
        font-size: 1.6rem;
        @include sp-width;
        top: -20rem;
        color: #000;
        text-align: left;
      }
      & span{
        display: block;
        @include mq(sp){
          display: inline;
        }
      }
    }
  }
  & img{
    width: calc(100vw / 3);
  }
}

@keyframes loop-right{
  0%{transform: translateX(-100%);}
  to{transform: translateX(0);}
}

@keyframes loop-left{
  0%{transform: translateX(0);}
  to{transform: translateX(-100%);}
}