@use "../setting/app" as *;

#policy-page{
  & .c-section-logo__wrap{
    margin: 0 0 5% 0;
    line-height: 2;
    @include mq(sp){
      padding: 0;
    }
  }
  & b,
  & strong{
    font-size: 2.4rem;
    @include mq(sp){
      font-size: 1.6rem;
    }
  }
}

.p-policy{
  width: 70%;
  margin: 0 auto 15% auto;
  @include mq(sp){
    @include sp-width;
    margin: 60% 0 0 0;
  }
  & p{
    text-align: justify;
    word-break:break-all;
  }
}