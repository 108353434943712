@use "../setting/app" as *;

.c-tab{
  &__btn{
    font-family: $poppins;
    display: block;
    font-size: 2rem;
    width: 13%;
    border-left: $solid;
    border-top: $solid;
    padding: .7%;
    text-align: center;
    cursor: pointer;
    @include mq(sp){
      width: 33.3333%;
      border: $solid-half;
    }
    &:last-child{
      border-right: $solid;
      @include mq(sp){
        border-right: $solid-half;
      }
    }
    &:hover{
      @include colorBtn;
    }
    &.js-window-clicked{
      @include colorBtn;
    }
  }
  &__company{
    width: 100%;
    position: absolute;
    top: 40%;
    text-align: center;
    opacity: 0;
    @include mq(sp){
      width: 50%;
      opacity: 1;
      top: 30%;
      left: 55%;
      text-align: left;
    }
  }
  &__item{
    width: 32%;
    margin: 0 2% 5% 0;
    color: #fff;
    background-color: #000;
    height: auto;
    position: relative;
    @include mq(sp){
      width: 100%;
      background-color: #fff;
      color: #000;

    }
    & img{
      border: solid 0.1px #ccc;
      @include mq(sp){
        width: 50%;
      }
    }
    &:hover img{
      opacity: 0.4;
      @include mq(sp){
        opacity: 1;
      }
    }
    & iframe{
      height: 2vw;
      @include mq(sp){
        height: 4vw;
      }
    }
    &:nth-of-type(3n){
      margin: 0 0 5% 0;
    }
  }
  &__kind{
    font-size: 1.8rem;
    border: solid 1px #fff;
    padding: 2%;
    position: absolute;
    top: 5%;
    left: 3%;
    opacity: 0;
    @include mq(sp){
      font-size: 1.2rem;
      border: $solid-half;
      opacity: 1;
      top: 0;
      left: 55%;
      padding: .5% 2%;
    }
  }
  &__sample{
    display: none;
    &.js-active{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @include mq(sp){
        flex-direction: column;
      }
    }
  }
  &__wrap{
    border-bottom: $solid;
    display: flex;
    flex-direction: row;
    margin: 7% 0;
    @include mq(sp){
      border: none;
    }
  }
}

.c-tab__item:hover .c-tab__kind, .c-tab__item:hover .c-tab__company{
  opacity: 1;
}