@use "../setting/app" as *;

.c-input{
  margin-bottom: 4%;
  & .u-err__msg{
    float: right;
  }
  & .js-count-area{
    float: right;
  }

  &__checkbox{
    width: 70%;
    margin: 0 auto;
    text-align: center;
    line-height: 2;
    & a{
      text-decoration: underline;
      &:hover{
        color: #0000EE;
      }
    }
    & span{
      @include mq(sp){
        display: block;
      }
    }
  }
  &__send{
    width: 45%;
    margin: 4% auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    @include mq(sp){
      width: 100%;
      margin: 10% auto 4% auto;
    }
    & input{
      font-family: $yugothic;
      @include colorBtn;
      transition: .3s;
      padding: 3%;
      &:hover{
        border-radius: 2vw;
      }
    }
    // 完了画面
    &--comp{
      width: 40%;
      margin: 9% auto 0 auto;
      padding: 2% 7%;
      @include colorBtn;
      @include mq(sp){
        width: 100%;
        margin: 9% auto;
      }
    }
    // 確認画面
    &--confirm{
      width: 55%;
      display: flex;
      margin: 0 auto;
      justify-content: space-evenly;
      @include mq(sp){
        width: 100%;
        flex-direction: column;
        padding: 10% 0 0 0;
      }
      & input{
        margin: 10% 0;
        font-family: $yugothic;
        padding: 3%;
        @include mq(sp){
          margin: 0 0 10% 0;
        }
        &:first-child{
          border: $solid;
          color: #000;
          width: 30%;
          background-color: transparent;
          @include mq(sp){
            width: 100%;
          }
        }
        &:last-child{
          @include colorBtn;
          border: none;
          width: 50%;
          @include mq(sp){
            width: 100%;
          }
        }
      }
    }
  }
}