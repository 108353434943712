@use "../setting/app" as *;

.c-table{
  &--border{
    margin: 0 auto 15% auto;
    font-size: 1.8rem;
    width: 100%;
    & a{
      color: #0000EE;
      text-decoration: underline;
    }
    small {
      font-size: 1.4rem;
      @include mq(sp){
        font-size: 1rem;
      }
    }
    & span{
      display: block;
      @include mq(sp){
        display: inline;
      }
    }
    & tr{
      display: flex;
      @include mq(sp){
        flex-direction: column;
      }
      &:last-child{
        & th{
          border-bottom: $solid;
          @include mq(sp){
            border-bottom: none;
          }
        }
        & td{
          text-align: justify;
          word-break:break-all;
          border-bottom: solid 1px rgb(165, 165, 165);
          @include mq(sp){
            border-bottom: $solid-half;
          }
        }
      }
    }
    & th{
      text-align: left;
      width: 20%;
      border-top: $solid;
      padding: 2% 0;
      @include mq(sp){
        padding: 4% 0 0 0;
        width: 100%;
        border-top: $solid-half;
      }
    }
    & td{
      border-top: solid 1px rgb(165, 165, 165);
      padding: 2% 0;
      width: 80%;
      @include mq(sp){
        width: 100%;
        padding: 0 0 4% 0;
        border: none;
      }
    }
  }

  &--check{
    text-align: left;
    font-size: 1.8rem;
    height: auto;
    width: 70%;
    max-width: 100%;
    margin: 5% auto;
    @include mq(sp){
      width: 100%;
      & tr{
        display: flex;
        flex-direction: column;
      }
    }
    & td{
      border: none;
      @include mq(sp){
        width: 100%;
      }
      &:first-child{
        padding: 2% 0;
        white-space: nowrap;
        width: 1%;
        & span{
          display: none;
          @include mq(sp){
            display: inline;
          }
        }
      }
      &:nth-child(2){
        width: 1%;
        text-align: center;
        @include mq(sp){
          display: none;
        }
      }
      &:last-child{
        padding: 2% 0;
        width: 10%;
        max-width: 70%;
        @include mq(sp){
          width: 100%;
          max-width: inherit;
          padding: 0;
          margin: 0 0 5% 0;
        }
      }
    }
  }
}