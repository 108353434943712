@use "../setting/app" as *;

.c-title{
  &__page{
    font-size: 10rem;
    line-height: 2.5;
    letter-spacing: 0.5vw;
    font-family: $ITCAvantGardeMM;
    @include mq(sp){
      font-size: 4.4rem;
    }
  }
  &__section{
    letter-spacing: 0.1vw;
    text-align: center;
    color: #fff;
    & h2{
      font-size: 4.5rem;
      font-family: $SourceHans;
      line-height: 1.1;
      @include mq(sp){
        font-size: 2rem;
      }
      & span{
        font-family: $ITCAvantGardeMM;
      }
    }
    & small{
      line-height: 4;
    }
    &--php{
      left: 33%;
    }
  }
  &__service{
    position: absolute;
    top: 22%;
    left: 12%;
    color: #fff;
    z-index: 4;
    @include mq(sp){
      top: 9%;
      left: 7%;
    }
  
    & h3{
      font-size: 3.7rem;
      font-family: $SourceHans;
      letter-spacing: 0.05vw;
      @include mq(sp){
        font-size: 1.4rem;
      }
      & span{
      font-family: $ITCAvantGardeMM;
      }
    }
  
    & > span{
      font-size: 2rem;
      margin: 12% 0 0 0;
      line-height: 2.2;
      opacity: 0;
      @include mq(sp){
        font-size: 1.2rem;
        margin: 3% 0 0 0;
        line-height: inherit;
      }
    }
  }
}