@use "../setting/app" as *;

#recruit-page{
  & .c-section-logo__wrap{
    position: inherit;
    margin: 0 0 13rem 0;
    line-height: 1.5;
    @include mq(sp){
      margin: 0 0 6rem 0;
    }
  }
}

.p-recruit{

  //＝＝＝＝＝詳細＝＝＝＝＝
  &-detail{
    @include contents-width;
    @include mq(sp){
      width: 100%;
    }
    &__img{
      margin: 0 0 0 auto;
      display: flex;
      justify-content: flex-end;
      @include mq(sp){
        width: 100%;
        margin: 2rem 0;
        justify-content: space-between;
      }
      & a{
        margin: 0 0 0 2rem;
        @include mq(sp){
          margin: 0;
          &:nth-of-type(2){
            margin: 0 4rem;
          }
        }
      }
    }
  }
  //＝＝＝＝＝エントリー＝＝＝＝＝
  &-entry{
    width: 100%;
    position: relative;
    @include mq(sp){
      padding: 0 0 6rem 0;
    }
    &__background{
      z-index: -1;
      width: 100%;
      padding: 34rem;
      position: absolute;
      top: 0;
      left: 0;
      background: url('#{$imgPath}/recruit/entry-back.webp') no-repeat center / cover;
      @include mq(sp){
        padding: 14rem;
        background: url('#{$imgPath}/recruit/sp/entry-back.webp') no-repeat center / cover;
      }
    }
  }

  //＝＝＝＝＝開発環境＝＝＝＝＝
  &-environment{
    @include contents-width;
    @include mq(sp){
      width: 100%;
    }
    & ul{
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 0 13rem 0;
      gap: 1rem;
      @include mq(sp){
        @include sp-width;
        gap: 2rem;
      }
      & li{
        width: calc(50% - .5rem);
        background-color: rgba(243, 244, 246);
        display: flex;
        align-items: center;
        @include mq(sp){
          width: calc(50% - 1rem);
          flex-direction: column;
        }
        & div{
          font-size: 1.8rem;
          @include mq(sp){
            text-align: center;
            padding: .5rem 0 1rem 0;
          }
          & > span{
            display: block;
            @include mq(sp){
              display: unset;
            }
          }
          & b{
            font-size: 2.2rem;
            display: block;
            @include mq(sp){
              & span{
                display: block;
              }
            }
          }
        }
        & img{
          width: 13rem;
          padding: 2.5rem;
          @include mq(sp){
            padding: 2rem;
          }
        }
      }
    }
  }

  & .p-circle{
    margin: 0;
  }

  //＝＝＝＝＝ポジション＝＝＝＝＝
  &-position{
    width: 160rem;
    margin: 0 0 0 auto;
    @include mq(sp){
      width: 100%;
      margin: 0;
    }

    &__wrap{
      font-size: 1.6rem;
      margin: 0 0 16rem 0;
      position: relative;
      letter-spacing: 0.2rem;
      background: url('#{$imgPath}/recruit/position-back.webp') no-repeat top right / 64%;
      @include mq(sp){
        @include sp-width;
        padding: 0 3rem 24rem 3rem;
        background: url('#{$imgPath}/recruit/sp/position-back.webp') no-repeat bottom / contain;
      }
      & h3{
        font-size: 1.8rem;
        font-family: $Noto;
        line-height: 1.7;
      }
      & p{
        width: 96rem;
        margin: 1.5rem 0 3rem 0;
        text-align: justify;
        word-break:break-all;
        @include mq(sp){
          width: 100%;
          margin: 2rem 0 3rem 0;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &-tab{
    display: none;
    &.active{
      display: inherit;
    }
  }
  //＝＝＝＝＝ワーキング＝＝＝＝＝
  &-working{
    @include contents-width;
    @include mq(sp){
      @include sp-width;
    }
    & .c-section-logo__wrap{
      @include mq(sp){
        padding: 0;
      }
    }
  }
}

#recruit_tab{
  display: flex;
  justify-content: center;
  height: 7.5rem;
  border-radius: 4rem;
  background-color: #ccc;
  font-size: 1.8rem;
  width: 100rem;
  margin: 0 0 2rem 0;
  @include mq(sp){
    font-size: 1.4rem;
    width: 100%;
  }
  li {
    cursor: pointer;
    border-radius: 4rem;
    padding: 1rem 4rem;
    height: 100%;
    background-color: #ccc;
    text-align: center;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    @include mq(sp){
      padding: 1rem;
      flex-direction: column;
      flex-wrap: nowrap;
      > div > span{
        display: block;
      }
    }
    &.active{
      @include colorBtn;
    }
  }
}