@use "../setting/app" as *;

.example, .place{
  color: #827f7f;
}

.u-w-100{
  width: 100%;
}

.u-w-49{
  width: 49%;
}

.u-err__msg{
  font-size: 1.8rem;
  font-weight: normal;
  color: red;
  &.u-textarea{
    @include mq(sp){
      width: 100%;
    }
  }
}

input.u-err__border, select.u-err__border, textarea.u-err__border{
  border: solid 1px red;
}

.u-list-on{
  list-style: inside;
  margin-left: 1%;
}

.u-list-num{
  margin-left: 0.5%;
}

// レスポンシブ時の切り替え用クラス
.u-pc{
  &__disp{
    @include mq(sp){
      display: none;
    }
  }
  &__half {
    width: 49%;
    @include mq(sp){
      width: 100%;
    }
  }
}

.u-sp{
  &__disp{
    display: none;
  }
}

@include mq(sp){
  .u-sp{
    &__disp{
      display: block;
    }
  }
}