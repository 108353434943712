@use "../setting/app" as *;

.p-chart{
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-around;
  margin-top: 4%;
  @include mq(sp){
    flex-direction: column;
  }
  &::before{
    content: '';
    display: block;
    padding-top: 17%;
    @include mq(sp){
      display: none;
    }
  }
  /* 文字のところ */
  &__list {
    li {
      text-align: center;
      position: absolute;
      list-style: none;
      &:first-child {
        font-family: $mb101, sans-serif;
        font-weight: bold;
        top: 69%;
        left: 43.5%;
        @include mq(sp){
          top: 42%;
        }
      }
    }
  }
  &__no {
    font-weight: bold;
    color: #fff;
    animation: fadeIn 1.5s ease-out 1s 1 forwards;
    opacity: 0;
    &::after{
      content: '%';
      font-size: 1.8rem;
    }
  }
  /* 転職者分布 */
  &__ratio {
    width: 50%;
    opacity: 0;
    position: absolute;
    top: 119%;
    left: 70%;
    animation: fadeIn 1.5s ease-out 1s 1 forwards;
    @include mq(sp){
      width: 100%;
      top: 96%;
      left: 5%;
      display: flex;
      flex-wrap: wrap;
    }
    li{
      line-height: 1.7;
      text-align: left;
      @include mq(sp){
        width: 50%;
      }
      &:first-child span{
        color: #72A7FF;
      }
      &:nth-of-type(2) span{
        color: #E6568C;
      }
      &:nth-of-type(3) span{
        color: #956FA0;
      }
      &:nth-of-type(4) span{
        color: #4ac06b;
      }
    }
    & span{
      margin-right: 1%;
    }
  }
  &__wrap {
    font-size: 2.3rem;
    width: 33%;
    position: relative;
    @include mq(sp){
      width: 100%;
      margin: 0 0 20% 0;
      &:last-child{
        margin: 0;
      }
    }
    display: none;
  }
}

/* activeをchart-wrapにつけて子要素の表示を全てactive時限定に */
.js-active .p-chart__wrap{
  display: block;
}

svg{
  width: 100%;
  height: 150%; /* lotateしているのでこちらがwidthになる*/
  display: inline-block;
  transform: rotate(-90deg);
  @include mq(sp){
    height: 25rem;
  }
}
circle{
  fill: transparent;
  stroke-width: 20%;
  width: 100%;
}
.circle1{
  stroke: #72A7FF;
}
.circle2{
  stroke: #E6568C;
}
.circle3{
  stroke: #956FA0;
}
.circle4{
  stroke: #4ac06b;
}

@keyframes fadeIn{
  0%{opacity:0;}
  to{opacity:1;}
}

/*＝＝＝＝＝ チャート１ ＝＝＝＝＝*/
/* サークルの動き */
#chart1 .circle1{
  animation: circleA1 1s forwards;
}
/* 5.65で1%分  */
@keyframes circleA1{
  0%{stroke-dasharray: 0, 200%;}
  to{stroke-dasharray: 10%, 200%;}
}

#chart1 .circle2{
  animation: circleA2 1s forwards;
}
@keyframes circleA2{
  0%{stroke-dasharray: 0, 200%;}
  to{stroke-dasharray: 130%, 200%;}
}

#chart1 .circle3{
  animation: circleA3 1s forwards;
}
@keyframes circleA3{
  0%{stroke-dasharray: 0, 200%;}
  to{stroke-dasharray: 201%, 200%;}
}
/* 文字の位置 */
#chart1 .chart-no1{
  top: 33%;
  left: 53%;
  @include mq(sp){
    top: 11%;
  }
}
#chart1 .chart-no2{
  top: 99%;
  left: 27%;
  @include mq(sp){
    top: 67%;
    left: 30%;
  }
}
#chart1 .chart-no3{
  top: 36%;
  left: 26%;
  @include mq(sp){
    top: 13%;
    left: 30%;
  }
}

/*＝＝＝＝＝ チャート2 ＝＝＝＝＝*/
/* サークルの動き */
#chart2 .circle1{
  animation: circleB1 1s forwards;
}
@keyframes circleB1{
  0%{stroke-dasharray: 0, 200%;}
  99.9%,to{stroke-dasharray: 100%, 200%;}
}

#chart2 .circle2{
  animation: circleB2 1s forwards;
}
@keyframes circleB2{
  0%{stroke-dasharray: 0, 200%;}
  99.9%,to{stroke-dasharray: 200%, 200%;}
}

/* 文字の位置 */
#chart2 .chart-no1{
  top: 101%;
  left: 50%;
  @include mq(sp){
    top: 68%;
    left: 51%;
  }
}
#chart2 .chart-no2{
  top: 42%;
  left: 22%;
  @include mq(sp){
    top: 17%;
    left: 28%;
  }
}

/*＝＝＝＝＝ チャート3 ＝＝＝＝＝*/
#chart3 .circle1{
  animation: circleD1 1s forwards;
}
@keyframes circleD1{
  0%{stroke-dasharray: 0,200%;}
  to{stroke-dasharray: 13%, 200%;}
}

#chart3 .circle2{
  animation: circleD2 1s forwards;
}
@keyframes circleD2{
  0%{stroke-dasharray: 0,200%;}
  to{stroke-dasharray: 58%, 200%;}
}

#chart3 .circle3{
  animation: circleD3 1s forwards;
}
@keyframes circleD3{
  0%{stroke-dasharray: 0,200%;}
  to{stroke-dasharray: 150%, 200%;}
}
#chart3 .circle4{
  animation: circleD4 1s forwards;
}
@keyframes circleD4{
  0%{stroke-dasharray: 0,200%;}
  to{stroke-dasharray: 200%, 200%;}
}

/* 文字の位置 */
#chart3 .chart-no1{
  top: 36%;
  left: 54%;
  @include mq(sp){
    top: 13%;
    left: 52%;
  }
}
#chart3 .chart-no2{
  top: 95%;
  left: 55%;
  @include mq(sp){
    top: 63%;
  }
}
#chart3 .chart-no3{
  top: 95%;
  left: 23%;
  @include mq(sp){
    top: 66%;
    left: 28%;
  }
}
#chart3 .chart-no4{
  top: 33%;
  left: 28%;
  @include mq(sp){
    top: 13%;
    left: 32%;
  }
}


/*＝＝＝＝＝ チャート4 ＝＝＝＝＝*/
#chart4 .circle1{
  animation: circleC1 1s forwards;
}
@keyframes circleC1{
  0%{stroke-dasharray: 0, 200%;}
  99.9%,to{stroke-dasharray: 80%, 200%;}
}

#chart4 .circle2{
  animation: circleC2 1s forwards;
}
@keyframes circleC2{
  0%{stroke-dasharray: 0, 200%;}
  99.9%,to{stroke-dasharray: 200%, 200%;}
}

/* 文字の位置 */
#chart4 .chart-no1{
  top: 44%;
  left: 57%;
  @include mq(sp){
    top: 20%;
    left: 54%;
  }
}
#chart4 .chart-no2{
  top: 94%;
  left: 23%;
  @include mq(sp){
    top: 68%;
    left: 29%;
  }
}