@use "../setting/app" as *;

.c-section-logo{
  font-size: 8rem;
  letter-spacing: 0.1vw;
  font-family: $ITCAvantGardeStd;
  @include mq(sp){
    font-size: 3.4rem;
  }
  &__wrap{
    line-height: 2;
    font-size: 2.2rem;
    @include mq(sp){
      font-size: 1.6rem;
      @include sp-width;
      margin: $center;
      line-height: 1.5;
      text-align: justify;
      word-break:break-all
    }
  }
}