@use "../setting/app" as *;

.c-modal{
  font-family: $koduka;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background: rgba(0,0,0,50%);
  transition: .7s;
  opacity: 0;
  transition: .3s;
  display: flex;
  align-items: center;
  &.js-active{
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  &__close{
    display: inline-block;
    position: absolute;
    top: 0;
    right: 1%;
    cursor: pointer;
    width: 3%;
    padding: 3%;
    @include mq(sp){
      top: -2%;
      right: 2%;
      padding: 4%;
    }
    &::before{
      transform: rotate(-45deg);
    }
    &::after{
      transform: rotate(45deg);
    }
  }
  &__item{
    width: 55%;
    background-color: #fff;
    display: flex;
    margin: 0 auto;
    position: relative;
    @include mq(sp){
      width: 80%;
    }
    & img{
      width: 50%;
      &.u-w-100{
        width: 100%;
      }
    }
    & span{
      font-family: $ITCAvantGardeMM;
      position: absolute;
      bottom: 3%;
      left: 3%;
      display: block;
      color: #fff;
    }
  }
  &__spell{
    color: rgb(141, 141, 141);
    line-height: 1.7;
    margin-bottom: 8%;
  }
  &__text{
    font-size: 1.8rem;
    width: 100%;
    margin: 4% 4% 0 4%;
    line-height: 1.7;
    position: relative;
    @include mq(sp){
      margin: 4%;
      & .p-company-member__name{
        font-size: 2.2rem;
      }
    }
  }
}

.c-modal__close::before, .c-modal__close::after{
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 50%;
  background: #333;
  @include mq(sp){
    height: 100%;
  }
}