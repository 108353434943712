@use "../setting/app" as *;

.c-gnavi{
  width: 100%;
  padding: 2% 5% 0 5%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  @include mq(sp){
    display: none;
  }
  & ul{
    width: 47%;
    font-size: 1.8rem;
    color: #fff;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    & li{
      font-family: $poppins;
      margin-left: 10%;
      position: relative;
      & a::before{
        content: "";
        display: block;
        position: absolute;
        bottom: -40%;
        left: 47%;
        background-color: #fff;
        border-radius: 50%;
        padding: 4px;
        opacity: 0;
        transform: translateY(50%);
        transition: $animation ease;
      }
      &:hover a::before{
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  & .c-logo{
    width: 18%;
  }

//＝＝＝＝＝sp版＝＝＝＝＝
  &__hamburger{
    transition: $animation;
    box-sizing: border-box;
    position: relative;
    width: 9%;
    height: 6.5vw;
    z-index: 99;
    & span {
      display: inline-block;
      transition: $animation;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #000;
      border-radius: 4px;
      &:nth-of-type(1) {
        top: 1%;
      }
      &:nth-of-type(2) {
        top: 50%;
      }
      &:nth-of-type(3) {
        bottom: 0;
      }
    }
    & span.js-active {
      &:nth-of-type(1) {
        transform: translateY(1rem) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-2rem) rotate(45deg);
      }
    }
  }
  &--sp{
    width: 100%;
    padding: 2% 5% 0 5%;
    display: none;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    @include mq(sp){
      display: flex;
    }
    & .c-logo{
      width: 45%;
      z-index: 99;
    }
    & ul{
      font-size: 2.5rem;
      font-family: $poppins;
      transform: translateX(100%);
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      padding: 25% 0 0 12%;
      background-color: #f2f5f5;
      line-height: 3;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      transition: $animation;
      z-index: 98;
      // overflow-y: scroll;
        &.js-active{
        transform: translateX(0);
      }
      & li{
        position: relative;
        & a:before{
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: -3rem;
          width: 1.7rem;
          height: 1px;
          background-color: #000;
        }
      }
    }
  }
}