@use "../setting/app" as *;

.p-page-top{
  height: auto;
  background-size: cover;
  &__img{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    padding: 22%;
    @include mq(sp){
      width: 100%;
      padding: 100% 0;
    }
    &--attract{
      background: url(#{$imgPath}/attract/top-back.webp) no-repeat top left / cover;
      @include mq(sp){
        background: url(#{$imgPath}/attract/sp/top-back.webp) no-repeat top left / contain;
      }
    }
    &--company{
      background: url(#{$imgPath}/company/top-back.webp) no-repeat top left / cover;
      @include mq(sp){
        background: url(#{$imgPath}/company/sp/top-back.webp) no-repeat top left / contain;
      }
    }
    &--contact{
      background: url(#{$imgPath}/contact/top-back.webp) no-repeat top left / cover;
      @include mq(sp){
        background: url(#{$imgPath}/contact/sp/top-back.webp) no-repeat top left / contain;
      }
    }
    &--policy{
      background: url(#{$imgPath}/policy/top-back.webp) no-repeat top left / cover;
      @include mq(sp){
        background: url(#{$imgPath}/policy/sp/top-back.webp) no-repeat top left / contain;
      }
    }
    &--recruit{
      background: url(#{$imgPath}/recruit/top-back.webp) no-repeat top left / cover;
      @include mq(sp){
        background: url(#{$imgPath}/recruit/sp/top-back.webp) no-repeat top left / contain;
      }
    }
    &--services{
      background: url(#{$imgPath}/service/top-back.webp) no-repeat top left / cover;
      @include mq(sp){
        background: url(#{$imgPath}/service/sp/top-back.webp) no-repeat top left / contain;
      }
    }
  }
  &__wrap{
    font-size: 2.6rem;
    color: #fff;
    width: 86%;
    margin: 6% 0 22% 14%;
    line-height: 1.8;
    letter-spacing: 0.1vw;
    @include mq(sp){
      font-size: 1.6rem;
      margin: 28% 0 0 0;
      @include sp-width;
    }
    & p{
      font-family: $ProN;
      & span{
        display: block;
        @include mq(sp){
          display: inline;
        }
      }
    }
  }
}