@use "../setting/app" as *;

#company-page{
  & .c-section-logo__wrap{
    position: inherit;
    width: 90%;
    margin: 0 0 7%;
    @include mq(sp){
      width: 100%;
    }
  }
  & h3{
    font-size: 6rem;
    margin-bottom: 5%;
    font-family: $ITCAvantGardeStd;
    @include mq(sp){
      font-size: 2.4rem;
    }
  }
}

#member .c-section-logo__wrap, #profile .c-section-logo__wrap{
  padding: 0;
}

#adviser,#awards{
  & h3{
    width: 90%;
    margin: 6% 0;
  }
}

#access{
  & .c-section-logo__wrap{
    width: 70%;
    margin: 0 auto 7% auto;
    @include mq(sp){
      @include sp-width;
    }
  }
}

#philosophy{
  @include contents-width;
  @include mq(sp){
    @include sp-width;
    padding: 0;
    margin: 35% 0 0 0;
  }
}

.p-company{
  //＝＝＝＝＝アクセス＝＝＝＝＝
  &-access{
    &__map{
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      @include mq(sp){
        width: 100%;
      }
      & > a{
        display: inline-block;
        border: $solid;
        margin: 8% auto 10% auto;
        padding: 2% 10%;
        cursor: pointer;
        transition: all .5s;
        &:hover{
          background-color: #000;
          color: #fff;
        }
        @include mq(sp){
          padding: 4% 23%;
        }
      }
      & > div{
        width: 100%;
        filter: grayscale(100%);
        overflow: hidden;
        @include mq(sp){
          filter: unset;
        }
        &:hover{
          filter: none;
        }
      }
    }
  }

  //＝＝＝＝＝メンバー＝＝＝＝＝
  &-member{
    @include contents-width;
    @include mq(sp){
      @include sp-width;
    }
    &__img{
      overflow: hidden;
      position: relative;
      color: rgb(0, 0, 0, 0);
      cursor: pointer;
      display: block;
      &:hover{
        background-color: rgb(0, 0, 0);
        color: #fff;
        & img{
          opacity: 0.6;
          transform: scale(1.15);
          transition: .8s;
          @include mq(sp){
            opacity: 1;
            transform: none;
          }
        }
        & .p-company-member__spell{
          opacity: 1;
          transition: .8s;
        }
      }
    }
    &__item{
      width: 22%;
      margin: 0 4% 3% 0;
      &:nth-child(4n){
        margin: 0 0 3% 0;
      }
      @include mq(sp){
        width: 48%;
        &:nth-of-type(2n){
          margin: 0 0 3% 0;
        }
      }
    }
    &__list{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &--advisor{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
    &__name{
      font-size: 2.6rem;
      font-weight: bold;
      margin-top: 6%;
      @include mq(sp){
        font-size: 1.6rem;
      }
    }
    &__position{
      font-size: 1.8rem;
      margin: 1% 0;
      line-height: 1.7;
      color: rgb(141, 141, 141);
      @include mq(sp){
        font-size: 1.4rem;
      }
      &--advisor span{
        font-weight: bold;
        color: #000;
      }
      &--modal{
        font-weight: bold;
        color: black;
      }
    }
    &__spell{
      font-size: 3rem;
      font-family: $ITCAvantGardeMM;
      width: 100%;
      height: 100%;
      text-align: center;
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0;
      background-color:	rgba(0,0,0,0.4);
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mq(sp){
        display: none;
      }
    }
  }

  //＝＝＝＝＝ミッション＝＝＝＝＝
  &-mission{
    position: relative;
    &__background{
      width: 100%;
      position: absolute;
      top: 0;
      left: 21.4%;
      padding: 17%;
      background: url(#{$imgPath}/company/back.webp) no-repeat center right / contain;
      @include mq(sp){
        left: 0;
        padding: 46% 0 0 0;
        background: url(#{$imgPath}/company/sp/back.webp) no-repeat center right / contain;
      }
    }
    &__wrap{
      position: relative;
      margin: 0 0 17%;
      @include mq(sp){
        @include sp-width;
      }
      & p{
        font-size: 4rem;
        font-family: $Noto;
        line-height: 2;
        @include mq(sp){
          font-size: 1.8rem;
        }
      }
    }
  }

  &-awards{
    @include contents-width;
    @include mq(sp){
      @include sp-width;
    }
    margin-bottom: 10rem;
    &__img {
      width: 40%;
      margin: 0 auto 2rem auto;
      @include mq(sp){
        width: 60%;
      }
    }
    div {
      text-align: center;
      @include mq(sp){
        span {
          display: block;
        }
      }
    }
  }

  //＝＝＝＝＝プロフィール＝＝＝＝＝
  &-profile{
    @include contents-width;
    @include mq(sp){
      @include sp-width;
      margin: 10% 0 0 0;
    }
  }

  //＝＝＝＝＝バリュー＝＝＝＝＝
  &-value{
    @include mq(sp){
      @include sp-width;
    }
    &__wrap{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & li{
        border-top: $solid;
        width: 45%;
        margin-bottom: 11%;
        @include mq(sp){
          width: 100%;
          border-top: $solid-half;
          & small{
            font-size: 1.4rem;
          }
        }
        & div{
          font-size: 6rem;
          font-family: $helvetica;
          margin-top: 2%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include mq(sp){
            font-size: 3.2rem;
          }
        }
        & h4{
          font-size: 2.6rem;
          font-weight: bold;
          margin: 2% 0 5% 0;
          @include mq(sp){
            font-size: 1.7rem;
          }
        }
        & p{
          font-size: 2rem;
          line-height: 1.7;
          text-align: justify;
          word-break:break-all;
          letter-spacing: 0.1vw;
          @include mq(sp){
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  //＝＝＝＝＝ビジョン＝＝＝＝＝
  &-vision{
    &__sdgs{
      width: 8%;
      display: flex;
      justify-content: right;
      float: right;
      @include mq(sp){
        width: 16%;
      }
      & img{
        margin-left: 11%;
      }
    }
    &__wrap{
      overflow: hidden;
      @include mq(sp){
        @include sp-width;
      }
      & p{
        font-size: 2.6rem;
        margin-left: 14%;
        margin-bottom: 5%;
        font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
        line-height: 2;
        letter-spacing: 0.1vw;
        @include mq(sp){
          font-size: 1.6rem;
          margin: 0 0 3% 0;
        }
      }
    }
  }
}