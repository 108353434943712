@use "../setting/app" as *;

.p-attract{
  &-knowhow{
    font-size: 2rem;
    width: 70%;
    margin: 0 auto 15% auto;
    @include mq(sp){
      @include sp-width;
      margin: 20% auto 15% auto;

      & ul{
        font-size: 1.4rem;
      }
    }
    & .c-section-logo__wrap p{
      line-height: 1.7;
      margin: 0 0 7% 0;
    }
    & div{
      &:first-child{
        margin: 0 0 3% 0;
        @include mq(sp){
          padding: 0;
        }
      }
      & > p{
        margin-top: 1%;
      }
    }
  }
}