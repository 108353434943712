@use "../setting/app" as *;

.l-footer{
  position: relative;
  background-color: rgb(45, 48, 55);
  padding: 5% 0;
  &__container{
    width: $pc2;
    margin: $center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @include mq(tb) {
      @include sp-width;
      flex-direction: column-reverse;
    }
  }
  &__menu{
    font-size: 1.6rem;
    width: 70%;
    margin-top: 1.5%;
    margin-left: 7%;
    line-height: 2.5;
    color: #fff;
    font-family: $poppins;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include mq(sp){
      width: 100%;
      margin: 0;
      flex-wrap: wrap;
    }
    & > li{
      @include mq(sp){
        width: 50%;
      }
      &:last-child{
        display: flex;
        flex-direction: column;
        @include mq(sp){
          position: absolute;
          right: 0%;
          top: 32%;
        }
        & a:last-child{
          position: absolute;
          top: 12.5vw;
          font-size: 0.7em; //親のフォントの相対比のためここだけem
          @include mq(sp){
            top: 11vw;
          }
        }
      }
    }
    & ul a{
      font-family: $BBB;
      color: rgb(205, 205, 205);
      @include mq(sp){
        font-size: 1.2rem;
      }
    }
  }
}
