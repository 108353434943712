/* フォント読み込み */
@font-face {
  font-family: 'ITCAvantGardeStd-Bk';
  src: url('../fonts/ITCAvantGardeStd-Bk.woff') format('woff');
}
@font-face {
  font-family: 'ITCAvantGardePro-Md';
  src: url('../fonts/ITCAvantGardePro-Md.woff') format('woff');
}
@font-face {
  font-family: 'Poppins Light';
  src: url('../fonts/Poppins-Light.woff') format('woff');
}
//GothicMB101 Med-83pv-RKSJ-H→ゴシックmb101 b ProMedium
//medium読み込んでいるのでfont-weightでboldに調整
@font-face {
  font-family: 'mb101 m';
  src: url('../fonts/A-OTF-Gothic-MB101-Pro-M.woff');
}
//源ノ角ゴシックR
@font-face {
  font-family: 'Source Han Sans Regular';
  src: url('../fonts/SourceHanSansJP-Regular.woff');
}
//小塚ゴシック Pr6N R
@font-face {
  font-family: 'koduka gothic pro6n r';
  src: url('../fonts/kodukapr6n-r.woff');
}

/* フォント */
$Noto: 'Noto Sans JP';
$mb101: 'mb101 m';
$ITCAvantGardeMM: 'ITCAvantGardePro-Md';
$ITCAvantGardeStd: 'ITCAvantGardeStd-Bk';
$poppins: "Poppins Light";
$helvetica: Helvetica, Arial, sans-serif;
//中ゴシックBBB代替
$SourceHans: 'Source Han Sans Regular';
//中ゴはadobeフォント
$BBB: Gothic Medium BBB, $SourceHans;
//mac"遊ゴシック"、win"遊ゴシック体"
$yugothic: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体";
$koduka: 'koduka gothic pro6n r';
$ProN: 'ヒラギノ角ゴ Pro W3', Hiragino Kaku Gothic ProN、ヒラギノ角ゴ ProN、Hiragino Kaku Gothic Pro、ヒラギノ角ゴ Pro, $Noto, $yugothic, "メイリオ", sans-serif;
/* メインフォント */
$mainfont: $yugothic, "ヒラギノ角ゴ Pro W3", $Noto, "メイリオ", sans-serif;
