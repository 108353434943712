@use "../setting/app" as *;

.p-services {
  &-flo {
    margin: 10% 0 0 0;
  }
  &__img {
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding: 13% 0 9% 0;
    background: url("#{$imgPath}/service/webintegration.webp") no-repeat center /
      cover;
    @include mq(sp) {
      width: 84%;
      background: url("#{$imgPath}/service/sp/webintegration.webp") no-repeat
        center / cover;
    }

    &--php {
      background: url("#{$imgPath}/service/phptensyoku.webp") no-repeat center /
        cover;
    }
  }
  &-php {
  }
  &__text {
    width: 80%;
    margin: 10% auto 0 auto;
    line-height: 2.5;
    text-align: center;
    @include mq(sp) {
      line-height: 2;
      @include sp-width;
    }
  }
  &-web {
    @include mq(sp) {
      margin: 62% 0 0 0;
    }
  }
  &-works {
    &__wrap {
      @include contents-width;
      @include mq(sp) {
        @include sp-width;
      }
      & .c-section-logo__wrap {
        position: relative;
        padding: 0;
      }
    }
  }
}

.p-services-web .c-flo__icon ul li p {
  line-height: inherit;
}

/* TASUKEAI */

.p-services-tasukeai {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 300px;
}

.p-services__img--tasukeai {
  // background-image: url("/assets/images/service/tasukeai.png");
  background-image: url("#{$imgPath}/service/tasukeai.webp");
  background-size: cover;
  background-position: center;
  width: 90%;
  height: 300px;
}

.p-services__text-container {
  width: 80%;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-services__text-tasukeai {
  display: block;
  margin-bottom: 25px;
  width: 80%;
}

.register-button {
  display: inline-block;
  padding: 15px 30px;
  margin-top: 20px;
  background-color: orange;
  color: white;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  animation: pulse 2s infinite;
}

/* ボタンのアニメーションスタイル */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.register-button:hover {
  background-color: darkorange;
  transform: scale(1.1);
}

.register-button:active {
  background-color: orangered;
  transform: scale(1.05);
}

/* スマホ表示用のスタイル */
@media screen and (max-width: 414px) {
  .p-services-tasukeai {
    margin: 0 0 0 0;
  }

  .p-services__img--tasukeai {
    width: 90%;
    height: 200px;
  }

  .p-services__text-container {
    margin-top: 50px;
    width: 90%;
  }

  .register-button {
    font-size: 14px;
    padding: 10px 20px;
    margin-bottom: 120px;
  }
}
