@use "../setting/app" as *;

// index.twig
.p-index{
  //＝＝＝＝＝カンパニー＝＝＝＝＝
  &-company{
    &__img{
      margin: 9% 0 20% 0;
      @include mq(sp){
        margin: 20% 0 10% 0;
        width: 100%;
        & img{
          width: 100%;
        }
      }
    }
    &__wrap{
      position: relative;
      margin: 0 auto;
      width: 70%;
      @include mq(sp){
        width: 100%;
      }
      & .c-icon__more{
        right: 2%;
        top: 14%;
        @include mq(sp){
          top: 35%;
          right: 10%;
        }
      }
    }
  }
  //＝＝＝＝＝コンタクト＝＝＝＝＝
  &-contact{
    width: 100%;
    position: relative;
    &__background{
      z-index: -1;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 22%;
      background: url('#{$imgPath}/top/contact-back.webp') no-repeat center / cover;
      @include mq(sp){
        padding: 32%;
        background: url('#{$imgPath}/top/sp/contact-back.webp') no-repeat top / contain;
      }
    }
  }

  //＝＝＝＝＝イントロダクション＝＝＝＝＝
  &-intro{
    position: relative;
    overflow: hidden;
    &__background{
      opacity: .2;
      @include mq(sp){
        padding: 3.2rem 0;
        img {
          scale: 1.2;
        }
      }
    }
    &__text{
      font-size: 2.6rem;
      line-height: 3.5;
      text-align: center;
      position: absolute;
      top: 20%;
      left: 20%;
      @include mq(sp){
        // font-size: 1.6rem;
        font-size: 1.4rem;
        line-height: 2;
        left: 10%;
        right: 10%;
        top: 10%;
        & span{
          display: block;
        }
      }
    }
    & .c-section-logo__wrap{
      position: absolute;
      bottom: 6%;
      left: 14%;
      @include mq(sp){
        left: 0;
      }
    }
  }
  //＝＝＝＝＝リクルート＝＝＝＝＝
  &-recruit{
    // width: 85%;
    // margin: 0 0 13% 15%;
    margin: 0 0 13% 0;
    position: relative;
    @include mq(sp){
      width: 100%;
      margin: 0;
    }
    & .c-section-logo__wrap{
      width: 70%;
      font-size: 1.8rem;
      margin: 0 auto;
      @include mq(sp){
        @include sp-width;
        margin: 55% auto 25% auto;
        & span{
          display: block;
        }
      }
    }
    & .c-icon__more{
      right: 20%;
      bottom: 3%;
      @include mq(sp){
        right: 10%;
        bottom: -10%;
      }
    }
    &__img{
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      display: flex;
      @include mq(sp){
        width: 100%;
        top: 25%;
      }
      img {
        width: 50%;
      }
    }
    &__text{
      font-size: 3rem;
      font-family: $mb101;
      font-weight: bold;
      // width: 100%;
      // margin: 0 0 15%;
      // padding: 4% 0 0 0;
      line-height: 3;
      width: 35%;
      padding: 13rem 0 0 0;
      margin: 0 auto 15% auto;
      color: #fff;
      @include mq(sp){
        font-size: 1.8rem;
        width: auto;
        color: #000;
        line-height: inherit;
        padding: 0 10%;
      }
    }
  }

  //＝＝＝＝＝サービス＝＝＝＝＝
  &-service{
    display: flex;
    @include mq(sp){
      flex-direction: column;
    }
    &:before{
      content: '';
      display: block;
      padding-top: 55%;
      @include mq(sp){
        padding: 0;
      }
    }
    &__img{
      width: 200%;
      background-color: #000;
      height: auto;
      position: absolute;
      top: 0;
      @include mq(sp){
        width: 100%;
        position: inherit;
        overflow: hidden;
      }
      & img{
        opacity: 0.3;
      }
    }
    &__slider{
      width: 50%;
      position: relative;
      margin-bottom: 10%;
      overflow: hidden;
      @include mq(sp){
        width: 100%;
        overflow: inherit;
      }
      &:hover{
        overflow: inherit;
        z-index: 2;
        &  .c-title__service > span{
          animation: fadeIn 1s;
          opacity: 1;
          display: inline-block;
        }
        // フェードインアニメーション
        @keyframes fadeIn{
          0%{opacity: 0;}
          to{opacity: 1;}
        }
      }

      &:first-child{
        @include mq(sp){
          margin-bottom: 0;
        }
        &:hover .p-index-service__img{
          animation: hover-left 1s forwards;
          left: 0;
          @include mq(sp){
            animation: none;
          }
        }
        // 左側スライドアニメーション
        @keyframes hover-left{
          0%{left: -77%;}
          to{left: 0;}
        }
        & .p-index-service__img{
          left: -77%;
          @include mq(sp){
            left: inherit;
            & img{
              // transform: translate3D(0, 0, 0);
            }
          }
        }
      }
      &:last-child{
        &:hover .p-index-service__img{
          animation: hover-right 1s forwards;
          right: 0;
          @include mq(sp){
            animation: none;
          }
        }
        // 右側スライドアニメーション
        @keyframes hover-right{
          0%{right: -39%;}
          to{right: 0;}
        }
        & .p-index-service__img{
          right: -39%;
          background-color: #1e0a32;
          @include mq(sp){
            right: inherit;
            & img{
              scale: 1.5;
              transform: translate3D(-5%, 10%, 0);
            }
          }

        }
      }

    }
  }
  //＝＝＝＝＝トップ＝＝＝＝＝
  &-top{
    padding-bottom: 6%;
    background : rgba(242, 245, 245, 1);
    height: auto;
    position: relative;
    & .c-logo{
      position: absolute;
      top: 7%;
      left: 9%;
      @include mq(sp){
        top: 2%;
        left: 7%;
        width: 50%;
      }
    }
    &__bottom{
      text-align: center;
      width: 75%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @include mq(sp){
        @include sp-width;
        text-align: left;
        position: relative;
        z-index: 2;
      }
      & p{
        font-size: 2rem;
        font-family: $mb101;
        font-weight: bold;
        line-height: 2;
        @include mq(sp){
          font-size: 1.4rem;
          text-align: left;
        }
        & strong{
          font-size: 5.5rem;
          @include mq(sp){
            font-size: 3rem;
            text-align: center;
            line-height: 1.5;
          }
        }
      }
      & .small{
        margin: 2% 0;
    
      }
    }
    &__img{
      width: 77%;
      @include mq(sp){
        width: 140%;
        margin: 0 -17% 0;
        overflow: hidden;
      }
    }
    &__header{
      display: flex;
      flex-direction: row;
      width: 93%;
      margin: 0 auto;
      overflow: hidden;
      @include mq(sp){
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
