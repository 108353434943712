@use "../setting/app" as *;

.p-contact{
  &__border{
    font-family: $ProN;
    position: relative;
    margin: 0 auto 18% auto;
    background-color: rgb(239, 238, 238);
    box-shadow: 5vw 2vw 2vw rgb(183, 183, 183);
    text-align: center;
    width: 80%;
    @include mq(sp){
      width: 100%;
      box-shadow: none;
      background-color: inherit;
      border-radius: 20px;
      border: solid 1.2rem #ccc;
    }
  }
  //＝＝＝＝＝完了画面＝＝＝＝＝
  &-complete{
    font-size: 2.6rem;
    font-family: $ProN;
    width: 70%;
    margin: 11% auto;
    padding: 9% 6%;
    text-align: center;
    border-radius: 60px;
    position: relative;
    background-color: #fff;
    @include mq(sp){
      width: 90%;
      padding: 0 6%;
      margin: 70% auto 20% auto;
    }
    &::before{
      position:absolute;
      content:"";
      top: -7px;
      bottom: -7px;
      left: -7px;
      right: -7px;
      background:linear-gradient(to right, #03939e 0%, #04b521 100%);
      z-index: -1;
      border-radius: 67px;
      @include mq(sp){
        // content: none;
      }
    }
    div{
      font-size: 3.8rem;
      font-weight: bold;
      margin: 0 0 7% 0;
      @include mq(sp){
        font-size: 1.8rem;
        padding: 4%;
        & p{
          text-align: left;
        }
        & span{
          display: block;
        }
      }
    }
    p {
      font-size: 2.2rem;
      font-weight: normal;
      @include mq(sp){
        font-size: 1.6rem;
      }
    }
  }

  //＝＝＝＝＝確認画面＝＝＝＝＝
  &-confirm{
    &__border{
      width: 100%;
      border-top: solid 3px rgb(183, 183, 183);
      border-bottom: solid 3px rgb(183, 183, 183);
    }
    &__check{
      @include mq(sp){
        @include sp-width;
        margin: 50% 0 0 0;
      }

      & form{
        flex-direction: column;
        font-weight: normal;
        width: 90%;
        flex-wrap: nowrap;
        margin: $center;
      }
      & h2{
        width: 100%;
        font-weight: bold;
        font-size: 3rem;
        margin: 0 auto;
        padding: 7%;
        @include mq(sp){
          font-size: 1.8rem;
          & span{
            display: block;
          }
        }
      }
    }
  }

  //＝＝＝＝＝お問い合わせ画面＝＝＝＝＝
  &-form{
    @include contents-width;
    @include mq(sp){
      @include sp-width;
      margin: 62% 0 0 0;
    }

    &__wrap{
      margin: 0 auto 13% auto;
      & .c-section-logo__wrap{
        font-size: 2.6rem;
        width: 90%;
        margin: 0 0 0 5%;
        @include mq(sp){
          font-size: 1.6rem;
          width: 100%;
          margin: 0;
          padding: 0;
        }
        & p{
          margin: 0 0 3% 0;
        }
      }
    }
    & form{
      width: 75%;
      margin: 4% auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include mq(sp){
        width: 100%;
      }
    }
  }
  & b{
    font-family: $ProN;
  }
}

