@use "./size" as *;

// メディアクエリ
$breakpoints: (
    "sp": "screen and (max-width: 414px)",
    "tb": "screen and (max-width: 1000px)", // 未使用
);

@mixin mq($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

// pcコンテンツ幅
@mixin contents-width{
    width: $pc1;
    margin: 0 auto;
}

// sp幅
@mixin sp-width{
    width: 100%;
    padding: 0 3.9rem;
}

//scroll,moreアイコン
@mixin icon {
    font-family: "Poppins Light";
    display: inline-block;
    border-radius: 50%;
    font-size: 1.6rem;
    border: $solid-half;
    cursor: pointer;
    padding: 3.8rem 2.9rem;
    @include mq(sp){
        font-size: 1rem;
        padding: 2rem 1.4rem;
    }
}

@mixin colorBtn {
    color: #fff;
    background-color: #000;
}