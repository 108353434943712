@use "../setting/app" as *;

.c-flo{
  width: 77%;
  margin: 0 auto 0 23%;
  position: relative;
  overflow: hidden;
  @include mq(sp){
    @include sp-width;
    padding: 0;
    margin: 8% 0 0 0;
  }
  & h3{
    font-size: 2.4rem;
    font-family: $mb101;
    font-weight: bold;
  }
  &__column{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  &__contents{
    font-size: 2rem;
    margin: 7% 0 20%;
    line-height: 2;
    @include mq(sp){
      @include sp-width;
    }
    &:nth-of-type(2){
      margin: 0 0 10%;
    }    
    & ul{
      margin: 2% 0 0 0;
      & li{
        line-height: 2.1;
        & span{
          margin: 0 2%;
          @include mq(sp){
              display: block;
              margin: 0;
          }
        }
      }
    }
  }
  &__icon{
    width: 90%;
    position: relative;
    padding: 10% 0 0 0;
    margin: 0 0 10% 0;
    @include mq(sp){
      @include sp-width;
    }
    & ul{
      &::before{
        content: '';
        display: block;
        width: 1px;
        height: 45%;
        background-color: #0000EE;
        position: absolute;
        top: 48%;
        left: 3.9%;
        z-index: -1;
        @include mq(sp){
          top: 24%;
          left: 12%;
          height: 70%;
        }
      }
      & li{
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 2% 0 0 2%;
        @include mq(sp){
          margin: 5% 0 0 0;
        }
        & div{
          width: 4%;
          @include mq(sp){
            width: 10%;
          }
        }
        & p{
          padding-left: 2%;
          line-height: 2.5;
          @include mq(sp){
            width: 90%;
            & span{
              display: block;
            }
          }
        }
      }
    }
  
    & .c-side-img{
      width: 58%;
    }
  }
  &__side{
    width: 80%;
    height: 100%;
    position: absolute;
    top: 4rem;
    right: 3rem;
    &--php {
      top: 32rem;
    }
    > div {
      position: relative;
      height: 100%;
      img {
        width: 50%;
        position: absolute;
        @include mq(sp){
          top: 17%;
          right: 0;
          width: 80%;
          &.img9{
            top: 34%;
          }
        }
        &.img-left{
          transform: $translate-lr-left;
          animation: loopA1 15s infinite linear 5s forwards;
        }
        @keyframes loopA1{
          0%{transform: $translate-lr-left;}
          5%, 25%{transform: $translate-lr-bottom;}
          30%, 50%{transform: $translate-lr-right;}
          55%, 75%{transform: $translate-lr-top;}
          80%, to{transform: $translate-lr-left;}
        }

        &.img-top{
          transform: $translate-tb-top;
          animation: loopA2 15s infinite linear 5s forwards;
        }
        @keyframes loopA2 {
          0%{transform: $translate-tb-top;}
          5%, 25%{transform: $translate-tb-left;}
          30%, 50%{transform: $translate-tb-bottom;}
          55%, 75%{transform: $translate-tb-right;}
          80%, to{transform: $translate-tb-top;}
        }

        &.img-right{
          transform: $translate-lr-right;
          animation: loopA3 15s infinite linear 5s forwards;
        }
        @keyframes loopA3{
          0%{transform: $translate-lr-right;}
          5%, 25%{transform: $translate-lr-top;}
          30%, 50%{transform: $translate-lr-left;}
          55%, 75%{transform: $translate-lr-bottom;}
          80%, to{transform: $translate-lr-right;}
        }

        &.img-bottom{
          transform: $translate-tb-bottom;
          animation: loopA4 15s infinite linear 5s forwards;
        }
        @keyframes loopA4 {
          0%{transform: $translate-tb-bottom;}
          5%, 25%{transform: $translate-tb-right;}
          30%, 50%{transform: $translate-tb-top;}
          55%, 75%{transform: $translate-tb-left;}
          80%, to{transform: $translate-tb-bottom;}
        }

        &--sp{
          top: 17%;

        }
      }
    }
  }
}
