@use "../setting/app" as *;

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

html{
  font-family: $mainfont;
  // 1900pxで10px
  font-size: calc(100vw / 190);
  @include mq(sp){
    // 480pxで10px
    font-size: calc(100vw / 48);
  }
}

body{
  font-size: 2.2rem;
  width: auto;
  height: 100%;
  @include mq(sp){
    font-size: 1.6rem;
  }
}

h1,h2,h3{
  font-weight: normal;
  font-size: unset;
  display: block;
}

a{
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

img{
  vertical-align: bottom;
  border: none;
  width: 100%;
}

iframe{
  width: 100%;
  border: none;
}

ul{
  list-style: none;
}

small{
  font-size: 1.6rem;
  letter-spacing: 0.02vw;
  font-family: $helvetica;
  font-weight: lighter;
  @include mq(sp){
    font-size: 1rem;
  }
}

input[type="text"], select{
  font-size: 2.4rem;
  font-family: $yugothic;
  background-color: $back-gray;
  margin: 1% auto;
  width: 100%;
  border: none;
  padding: 2rem;
  @include mq(sp){
    font-size: 1.6rem;
    padding: 1.6rem;
  }
}

input[type="checkbox"] + label::before, input[type="checkbox"] + label::after{
  content: '';
  display: block;
  position: absolute;
  top: 0;
  @include mq(sp){
    top: -15%;
  }
}

input[type="checkbox"]{
  display: none;
  & + label{
    font-weight: bold;
    position: relative;
    cursor: pointer;
    &::before{
      left: -3rem;
      padding: 1rem;
      border: $solid;
    }
    &::after{
      left: -2.8rem;
      padding: 0.5rem 1rem;
      border-bottom: $check;
      border-left: $check;
      transform: rotate(-45deg);
      z-index: 3;
      opacity: 0;
    }
  }
  &:checked + label::after{
    opacity: 1;
  }
}

input[type="submit"]{
  cursor: pointer;
  font-size: 2.6rem;
  text-align: center;
  @include mq(sp){
    font-size: 1.6rem;
  }
}

// 上記2remだと若干高さが違うのでここで打ち消し
select{
  padding: 2.5rem 2rem;
  appearance: none;
  background: $back-gray url(#{$imgPath}/contact/select_caret.webp) no-repeat center right 2rem / 2rem;
  @include mq(sp){
    padding: 1.8rem 1.6rem;
  }
}

textarea{
  font-size: 2.4rem;
  background-color: $back-gray;
  border: none;
  padding: 2rem;
  margin: 1% auto;
  width: 100%;
  @include mq(sp){
    font-size: 1.6rem;
    padding: 1.6rem;
  }
  &::placeholder{
    font-weight: normal;
    color: #827f7f;
  }
}