@use "../setting/app" as *;

.c-company{
  &__address{
    width: 30%;
    min-width: 272px;
    color: #fff;
    & p{
      font-size: 1.5rem;
      font-family: $BBB;
      margin-top: 7%;
      line-height: 2;
      color: rgb(228, 226, 226);
    }
    & span{
      font-size: 1.3rem;
      font-family: $poppins;
      @include mq(sp){
        display: block;
        text-align: center;
        margin: 5% 0 0 0;
      }
    }
    &--footer{
      @include mq(sp){
        width: 100%;
        margin: 8% 0 5% 0;
      }
      & .c-logo{
        width: 70%;
      }
    }
  }
}